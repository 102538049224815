import $ from 'jquery'
import Pusher from 'pusher-js'

export default class SimpleroPusher {
  static initClass() {
    this._object = null
    this.prototype._pusher = null
    this.prototype._channel = []
    this.options = {}
  }

  static bind(channel, event, callback, fetch) {
    if (this._object == null) {
      this._object = new SimpleroPusher()
    }
    return this._object.bind(channel, event, callback, fetch)
  }

  static unbind(channel, event, callback = undefined) {
    if (this._object == null) {
      this._object = new SimpleroPusher()
    }
    return this._object.channel(channel).unbind(event, callback)
  }

  static trigger(channel, event, data) {
    if (this._object == null) {
      this._object = new SimpleroPusher()
    }
    return this._object.trigger(channel, event, data)
  }

  static get connection() {
    if (this._object == null) {
      this._object = new SimpleroPusher()
    }
    return this._object.connection
  }

  constructor() {
    if (this._pusher == null) {
      this._pusher = new Pusher(
        $('body').data('pusher-key'),
        $('body').data('pusher-options')
      )
    }
  }

  channel(channel) {
    return (
      this._channel[channel] ||
      (this._channel[channel] = this._pusher.subscribe(channel))
    )
  }

  bind(channel, event, callback, fetch) {
    this.channel(channel).bind(event, callback)
    // Also fetch any events that we may have just missed
    if (fetch) {
      this.fetchEvents(channel, event, callback)
      setTimeout(() => {
        this.fetchEvents(channel, event, callback)
      }, 2000)
    }
  }

  fetchEvents(channel, event, callback) {
    return $.ajax({
      url: `/pusher/${channel}/${event}`,
      type: 'POST',
      success(events, _status, _xhr) {
        return Array.from(events).map((data) => callback.call(null, data))
      },
    })
  }

  trigger(channel, event, data) {
    this.channel(channel).trigger(event, data)
  }

  get connection() {
    return this._pusher.connection
  }
}
SimpleroPusher.initClass()
